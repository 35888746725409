import React, { useState } from "react";
import { graphql } from "gatsby";
import { LoadScript } from "@react-google-maps/api";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { Accordion, Badge, Col, Container, Row } from "react-bootstrap";

import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import Map from "../components/elements/Map/Map";
import { constructActiveDoc } from "../helpers";
import { Lightning } from "../helpers/Icons";

import "../styles/pages/charge-points.scss";

const ChargePoints = ({ data }) => {
  if (!data) return null;

  const { settings, page } = data;

  const [googleSettings] = useState({
    key:
      process.env.GATSBY_GOOGLE_MAPS_KEY !== ""
        ? process.env.GATSBY_GOOGLE_MAPS_KEY
        : null,
    libraries: ["places", "geometry", "drawing"],
  });

  return (
    <>
      <Seo page={page} settings={settings} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <div className="charge-points-map horizontal-shadow horizontal-shadow--bottom">
          <LoadScript
            googleMapsApiKey={googleSettings.key}
            libraries={googleSettings.libraries}
            loadingElement={
              <div
                className="charge-points-map__loading"
                style={{ height: "100%" }}
              />
            }
          >
            <Map data={page.data} />
          </LoadScript>
        </div>
        <Accordion as="aside" className="mt-3 mb-5">
          <Accordion.Item eventKey="0">
            <Container className="mw-400">
              <Accordion.Header>{page.data.ui_open}</Accordion.Header>
            </Container>
            <Accordion.Body>
              <Container
                className="overflow-auto"
                style={{ maxHeight: "500px" }}
              >
                <Row>
                  {page.data.map.map((loc) => (
                    <Col lg="3" className="mb-5">
                      {loc.name}
                      <br />
                      <a
                        href={`http://www.google.com/maps/place/${loc.coordinates.latitude},${loc.coordinates.longitude}`}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="small link-light opacity-75"
                      >
                        {loc.address}
                      </a>
                      <br />
                      <Badge
                        className="border border-primary fw-normal text-wrap"
                        bg={null}
                        text="primary"
                      >
                        <Lightning /> {loc.charger}
                      </Badge>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Layout>
    </>
  );
};

export const query = graphql`
  query chargePointsQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicChargePoints(lang: { eq: $lang }) {
      ...prismicChargePointsFragment
    }
  }
`;

export default withPrismicPreview(ChargePoints);
