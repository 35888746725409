import { isBrowser } from "../helpers/checkEnvironment";

const mapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#152b30",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575",
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8c7768",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8c7768",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#181818",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1b1b1b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#8c7768",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8a8a8a",
      },
    ],
  },
  {
    featureType: "road.arterial",
    stylers: [
      {
        saturation: -35,
      },
      {
        lightness: -45,
      },
    ],
  },
  {
    featureType: "road.highway",
    stylers: [
      {
        lightness: -30,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.fill",
    stylers: [
      {
        saturation: -70,
      },
      {
        lightness: -55,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8c7768",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8c7768",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#122529",
      },
      {
        lightness: -25,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#3d3d3d",
      },
    ],
  },
];

const clusterStyles = [
  {
    url: `${isBrowser ? window.location.origin : ""}/images/cluster-1.png`,
    width: 53,
    height: 52,
    anchorText: [-5, 0],
    textColor: "#000",
    textSize: 16,
  },
  {
    url: `${isBrowser ? window.location.origin : ""}/images/cluster-2.png`,
    width: 56,
    height: 55,
    anchorText: [-5, 0],
    textColor: "#000",
    textSize: 16,
  },
  {
    url: `${isBrowser ? window.location.origin : ""}/images/cluster-3.png`,
    width: 66,
    height: 65,
    anchorText: [-5, 0],
    textColor: "#000",
    textSize: 16,
  },
  {
    url: `${isBrowser ? window.location.origin : ""}/images/cluster-4.png`,
    width: 78,
    height: 77,
    anchorText: [-5, 0],
    textColor: "#000",
    textSize: 16,
  },
  {
    url: `${isBrowser ? window.location.origin : ""}/images/cluster-5.png`,
    width: 90,
    height: 89,
    anchorText: [-5, 0],
    textColor: "#000",
    textSize: 16,
  },
];

export { mapStyles, clusterStyles };
